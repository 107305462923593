import React from 'react';

import Section from '../../components/section';
import {PageHero} from '../../components/page-hero';
import Layout from '../../components/layout/layout';
import FAQItem from '../../components/FAQ-item';
import Tabs from '../../components/tabs';

import {tabs} from '../../helpers/constants/index';

import './style.scss';

const Glossary: React.FC = (): JSX.Element => {
  return (
    <Layout>
      <PageHero title="Glossary" subtitle="Useful Resources and Glossary for better understanding the World Wide Web" />
      <div className="glossary-tabs">
        <Tabs dataTabs={tabs} />
      </div>
      <Section>
        <div className="col-12 d-block mb_20" style={{maxWidth: '1200px', width: '100%'}}>
          <FAQItem
            title="A record or Address record"
            text={
              <>
                <p>
                  A record maps a domain name to the IP address of the computer hosting that domain. For example, to
                  access the Hexometer website you enter hexometer.com and there’s an A record that points to the
                  particular IP address. This means all requests from the browser to hexometer.com are directed to the
                  server with that IP address.
                </p>
              </>
            }
          />
          <FAQItem
            title="Domain Name"
            text={
              <>
                <p>
                  A Domain Name is an identification string that defines a realm of administrative autonomy, authority
                  or control within the Internet. Domain names are used in various networking contexts and for
                  application-specific naming and addressing purposes. In general, a domain name identifies a network
                  domain, or it represents an Internet Protocol (IP) resource, such as a personal computer used to
                  access the Internet, a server computer hosting a web site, or the web site itself or any other service
                  communicated via the Internet.
                </p>
                <p>
                  To see domain name registration information simply check the whois data at
                  <a href="https://hexometer.com/domain-whois"> https://hexometer.com/domain-whois</a>
                </p>
              </>
            }
          />
          <FAQItem
            title="DNS - Domain Name System"
            text={
              <>
                <p>
                  DNS is a hierarchical and decentralized naming system for computers, services, or other resources
                  connected to the Internet or a private network. It associates various information with domain names
                  assigned to each of the participating entities.
                </p>
              </>
            }
          />
          <FAQItem
            title="MX record - Mail Exchanger record"
            text={
              <>
                <p>
                  MX record specifies the mail server responsible for accepting email messages on behalf of a domain
                  name. It is a resource record in the Domain Name System (DNS). It is possible to configure several MX
                  records, typically pointing to an array of mail servers for load balancing and redundancy.
                </p>
              </>
            }
          />
          <FAQItem
            title="TLD - Top-level Domain"
            text={
              <>
                <p>
                  <b>TLD</b> is one of the domains at the highest level in the hierarchical Domain Name System of the
                  Internet. The TLDs are installed in the root zone of the namespace. For all domains in lower levels,
                  it is the last part of the domain name, that is, the last label of a fully qualified domain name. For
                  example, in the domain name www.hxometer.com, the top-level domain is <b>com</b>. Responsibility for
                  management of most top-level domains is delegated to specific organizations by the <b>ICANN</b>{' '}
                  (Internet Corporation for Assigned Names and Numbers), which operates the <b>IANA</b> (Internet
                  Assigned Numbers Authority), and is in charge of maintaining the <b>DNS</b> (Domain Name System) root
                  zone.
                </p>
                <h6>
                  <b>IANA currently distinguishes the following groups of top-level domains:</b>
                </h6>
                <ul>
                  <li>
                    <span>
                      <b>ccTLD</b> - country-code top-level domains{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>gTLD</b> - generic top-level domains{' '}
                    </span>
                  </li>
                  <li>
                    <span>
                      <b>.arpa</b> - infrastructure top-level domain{' '}
                    </span>
                  </li>
                </ul>
                <p>
                  Full list of TLDs is available at:
                  <a
                    href="https://en.wikipedia.org/wiki/List_of_Internet_top-level_domains"
                    target="_blank"
                    rel="noopener"
                  >
                    {' '}
                    https://en.wikipedia.org/wiki/List_of_Internet_top-level_domains
                  </a>
                </p>
              </>
            }
          />
          <FAQItem
            title="Backlinks"
            text={
              <>
                <p>
                  Backlink - a link from some other website (the referrer) to that web resource (the referent). A web
                  resource may be (for example) a website, web page, or web directory. Have you had a chance to answer
                  the previous question?
                </p>
              </>
            }
          />
          <FAQItem
            title="Broken Links"
            text={
              <>
                <p>
                  Broken link ( dead or “rotten” link) is a hyperlink that is linked to an empty or non-existent webpage
                  or file. When a broken link is clicked or requested, an error message is posted.
                </p>
              </>
            }
          />
          <FAQItem
            title="External Links"
            text={
              <>
                <p>
                  External Link is a type of hyperlink that points at (target) any page of the website or domain name
                  other than the domain the link exists on (source).
                </p>
              </>
            }
          />
          <FAQItem
            title="Alt tag"
            text={
              <>
                <p>
                  <b>alt</b> an HTML attribute of the <b>img</b> tag and it is responsible for the text that gets
                  displayed in case the image can’t be loaded (if the file is missing, for example).
                </p>
                <pre>
                  <span>&lt;img src=”dog.jpg” alt=”picture of a dog” /&gt;</span>
                </pre>
              </>
            }
          />
          <FAQItem
            title="Anchor text"
            text={
              <>
                <p>Each link consists of two main elements:</p>
                <ol>
                  <li>
                    <span>web address that the link is pointing to (the destination)</span>
                  </li>
                  <li>
                    <span>the anchor text - the text that works as the link</span>
                  </li>
                </ol>
                <p>
                  Here’s a link to our home page and the destination of this link is https://hexometer.com, and the
                  anchor text is “home page”.
                </p>
              </>
            }
          />
          <FAQItem
            title="Canonical tag"
            text={
              <>
                <p>
                  Canonical URL (sometimes referred to as a canonical tag or link) is an HTML element that helps
                  webmasters prevent duplicate content by specifying the “canonical” or “preferred” version of a web
                  page.
                </p>
              </>
            }
          />
          <FAQItem
            title="Duplicate content"
            text={
              <>
                <p>Duplicate content is content that appears on the internet in more than one place.</p>
              </>
            }
          />
          <FAQItem
            title="Meta description"
            text={
              <>
                <p>
                  <a href="https://hexometer.com/meta-tag-analyzer">Meta description</a> is a tag in HTML (recommended
                  up to 158 characters) – which summarizes a page's content. Search engines show the meta description in
                  search results mostly when the searched-for phrase is within the description, so optimizing the meta
                  description is crucial for on-page SEO.
                </p>
              </>
            }
          />
          <FAQItem
            title="Robots.txt"
            text={
              <>
                <p>
                  robots.txt is a text file that tells spiders (most often search engines) which pages on your site to
                  crawl and which pages not to crawl.
                </p>
              </>
            }
          />
          <FAQItem
            title="Sitemap.xml"
            text={
              <>
                <p>
                  sitemap.xml contains the map of all the different URLs that your website contains (all pages, posts,
                  archives, etc).
                </p>
              </>
            }
          />
          <FAQItem
            title="SEM - Search Engine Marketing"
            text={
              <>
                <p>
                  SEM is a form of Internet marketing that involves the promotion of websites by increasing their
                  visibility in search engine results pages (SERPs) primarily through paid advertising.
                </p>
              </>
            }
          />
          <FAQItem
            title="SEO - Search Engine Optimization"
            text={
              <>
                <p>
                  SEO is the process of optimizing the website to get organic, or non-paid, traffic from the search
                  engine results page.
                </p>
              </>
            }
          />
          <FAQItem
            title="SERP - Search Engine Results Page"
            text={
              <>
                <p>SERPs are the result pages displayed by search engines in response to a query by a searcher.</p>
              </>
            }
          />
          <FAQItem
            title="Spider (crawler, bot, robot)"
            text={
              <>
                <p>
                  Spider or Crawler is a special software that browses the web and collects data, looks for new sites
                  and content, checks what’s going on on them and sends the data back to the server.
                </p>
              </>
            }
          />
          <FAQItem
            title="Title tag"
            text={
              <>
                <p>
                  Title tag - is an HTML element (up to 60 characters) that specifies the title of a web page and
                  visible on browser’s title bar.
                </p>
              </>
            }
          />
          <FAQItem
            title="URL - Uniform Resource Locator"
            text={
              <>
                <p>
                  URL - is used to specify addresses on the World Wide Web. A URL is the fundamental network
                  identification for any resource connected to the web.
                </p>
              </>
            }
          />
          <FAQItem
            title="API - Application Programming Interface"
            text={
              <>
                <p>
                  API is a mechanism that allows different systems to communicate with each other. Developers create
                  APIs so that data that exists in their system can be made available in other applications or websites.
                </p>
              </>
            }
          />
          <FAQItem
            title="CMS - Content Management System"
            text={
              <>
                <p>
                  CMS - manages the creation and modification of digital content. It typically supports multiple users
                  in a collaborative environment.
                </p>
              </>
            }
          />
          <FAQItem
            title="Code Injection"
            text={
              <>
                <p>
                  Code injection is the process when hacker introduces (or "inject") code into the insecure computer
                  program and change how the program was intended to work, it usually happens when the website does not
                  validate data that is received.
                </p>
              </>
            }
          />
          <FAQItem
            title="CSS - Cascading Style Sheets"
            text={
              <>
                <p>
                  CSS is a style sheet language used for describing the presentation of a document written in a markup
                  language like HTML. CSS is a cornerstone technology of the World Wide Web, alongside HTML and
                  JavaScript.
                </p>
              </>
            }
          />
          <FAQItem
            title="Domain Verification by Facebook"
            text={
              <>
                <p>
                  Domain Verification by Facebook provides a way for you to claim ownership of your domain in Facebook
                  Business Manager. This ownership allows you to control editing privileges of your links and other
                  content to prevent misuse of your domain and to keep bad actors from spreading misinformation.
                </p>
              </>
            }
          />
          <FAQItem
            title="DMARC - Domain-based Message Authentication, Reporting & Conformance"
            text={
              <>
                <p>
                  DMARC is designed to fit into an organization’s existing inbound email authentication process. The way
                  it works is to help email receivers determine if the purported message “aligns” with what the receiver
                  knows about the sender.
                </p>
              </>
            }
          />
          <FAQItem
            title="FCP - First Contentful Paint"
            text={
              <>
                <p>
                  FCP measures the time from navigation to the time when the browser renders the first bit of content
                  from the DOM. This is an important metric to measure the{' '}
                  <a href="https://hexometer.com/page-speed-scanner/"> page loading speed.</a>
                </p>
              </>
            }
          />
          <FAQItem
            title="HTTP - HyperText Transfer Protocol"
            text={
              <>
                <p>
                  HTTP is the underlying protocol used by the World Wide Web and this protocol defines how messages are
                  formatted and transmitted, and what actions Web servers and browsers should take in response to
                  various commands.
                </p>
              </>
            }
          />
          <FAQItem
            title="Ping Test"
            text={
              <>
                <p>
                  Ping checks if a web host (or website address) or <b>IP</b> address is reachable across the Internet
                  by sending multiple <b>ICMP</b> (Internet Control Message Protocol) packets and listening for the
                  replies.
                </p>
              </>
            }
          />
          <FAQItem
            title="RWD - Responsive Web Design"
            text={
              <>
                <p>
                  RWD is a web design technique used to create one website that automatically reformats for the best
                  user viewing experience on a variety of devices and screen widths, i.e. desktops, laptops, tablets,
                  and mobile phones.
                </p>
              </>
            }
          />
          <FAQItem
            title="Tech Stack - Technology Stack"
            text={
              <>
                <p>
                  Tech stack is the combination of programming languages, tools, and frameworks that the developers use
                  to create web and mobile applications or a set of software that provides the infrastructure for a
                  computer. The stacks differ whether installed in a client-side and server-side, also popular as front
                  end and back end.
                </p>
              </>
            }
          />
          <FAQItem
            title="UAT - User Acceptance Testing"
            text={
              <>
                <p>
                  UAT is the process when actual software users test the software to make sure it can handle required
                  tasks in real-world scenarios, according to specifications.
                </p>
              </>
            }
          />
          <FAQItem
            title="UI - User Interface"
            text={
              <>
                <p>UI is the space where interactions between humans and machines occur.</p>
              </>
            }
          />
          <FAQItem
            title="UX - User Experience"
            text={
              <>
                <p>
                  UX is the person's emotions and attitudes about using particular software, system or service. It
                  includes the practical, experiential, affective, meaningful and valuable aspects of human-computer
                  interaction and product ownership.
                </p>
              </>
            }
          />
          <FAQItem
            title="WebDAV - Web Distributed Authoring and Versioning"
            text={
              <>
                <p>
                  WebDAV is an extension of the Hypertext Transfer Protocol that allows clients to perform remote Web
                  content authoring operations.
                </p>
              </>
            }
          />
          <FAQItem
            title="W3C -  World Wide Web Consortium"
            text={
              <>
                <p>
                  W3C is an international community that develops open standards to ensure the long-term growth of the
                  Web. Follow the link to validate your website{' '}
                  <a href="https://hexometer.com/w3c-validator">https://hexometer.com/w3c-validator</a>
                </p>
              </>
            }
          />
        </div>
      </Section>
    </Layout>
  );
};

export default Glossary;
